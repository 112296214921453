import React from "react";

import PostItem from "../PostItem";
import "./PostList.scss";

const PostList = ({ posts }) => {
  let i = 0;
  
  return (
    <>
      <div className="post-item-title">
        <div className="title">
          <b>Title</b>
        </div>
        <div className="username">
          <b>Username</b>
        </div>
        <div className="description">
          <b>Content</b>
        </div>
      </div>

      {posts.map((post) => (
        <PostItem key={i++} post={post} />
      ))}
    </>
  );
};

export default PostList;
