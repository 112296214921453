import React from "react";
import { Link, useLocation } from "react-router-dom";

import "./PostInfo.scss";

const PostInfo = () => {
  const location = useLocation();

  if (!location.state) {
    return <div>ERROR</div>;
  }

  const post = location.state.data;

  return (
    <>
      <div className="post">
        <div id="title">
          <h2>{post.title}</h2>
        </div>
        <div id="username">
          <b>Username: </b>
          {post.username}
        </div>
        <div id="content">
          <b>Content: </b>
        </div>
        {post.content}
      </div>
      <div className="back">
        <Link to="/" id="back-link">
          Go Back
        </Link>
      </div>
    </>
  );
};

export default PostInfo;
