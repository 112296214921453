import { Routes, Route } from "react-router-dom";

import Main from "../Main";
import Header from "../Header";
import Sidebar from "../Sidebar";
import PostInfo from "../PostInfo";
import Writer from "../Writer";

import "../App";
import "./App.scss";

function App() {
  return (
    <div id="container">
      <div className="header">
        <Header />
      </div>
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/posts" element={<PostInfo />} />
          <Route path="/write" element={<Writer />} />
          <Route path="*" element={<Main />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
