import React from "react";
import { Link } from "react-router-dom";

const PostItem = ({ post }) => {
  return (
    <Link
      to={{
        pathname: "/posts",
      }}
      state={{ data: post }}
      className="post-item"
    >
      <div className="title">{post.title}</div>
      <div className="username">{post.username}</div>
      <div className="description">{post.content}</div>
    </Link>
  );
};

export default PostItem;
