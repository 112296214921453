import React, { useState } from "react";
import axios from "axios";

import { url, homepage } from "../_variable";
import "./Writer.scss";

const Writer = () => {
  const [title, setTitle] = useState("");
  const [username, setUsername] = useState("");
  const [content, setContent] = useState("");

  const titleHandler = (e) => {
    e.preventDefault();
    setTitle(e.target.value);
  };

  const usernameHandler = (e) => {
    e.preventDefault();
    setUsername(e.target.value);
  };

  const contentHandler = (e) => {
    e.preventDefault();
    setContent(e.target.value);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    let formData = {
      title: title,
      username: username,
      content: content,
    };

    await axios({
      method: "POST",
      url: url,
      data: formData,
      headers: {
        "Content-Type": "text/plain",
      },
    });

    window.location.replace(homepage);
  };

  return (
    <>
      <main>
        <div id="title">
          <h2>New Post</h2>
        </div>
        <div className="container">
          <form onSubmit={submitHandler}>
            <label>Title</label>
            <input type="text" value={title} onChange={titleHandler}></input>
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={usernameHandler}
            ></input>
            <label>Content</label>
            <input
              type="text"
              value={content}
              onChange={contentHandler}
            ></input>
            <button type="submit">Submit</button>
          </form>
        </div>
      </main>
    </>
  );
};

export default Writer;
