import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { url } from "../_variable";
import PostList from "../PostList";
import "./Main.scss";

const Home = () => {
  const [postData, setPostData] = useState(null);

  const getPosts = async () => {
    const data = await axios.get(url);
    setPostData(data.data);
  };

  useEffect(() => {
    if (!postData) {
      getPosts();
    }
  }, [postData]);

  const renderContent = (postData) => {
    if (!postData) {
      return <div>Loading...</div>;
    } else {
      return (
        <>
          <div id="title">
            <h2>{postData.length} Total Posts</h2>
            <Link to="/write" id="new-post">
              New Post
            </Link>
          </div>
          <PostList posts={postData} />
        </>
      );
    }
  };

  return <main>{renderContent(postData)}</main>;
};

export default Home;
