import React from "react";
import { Link } from "react-router-dom";

import logo from "../../images/logo_t.png";

import "./Sidebar.scss";

const Sidebar = ({ curSector }) => {
  const menus = [
    {
      menu: "Trending",
      icon: "fas fa-fire",
      pathname: "/trending",
    },
    { menu: "Recent", icon: "fas fa-clock", pathname: "/recent" },
    { menu: "Chat", icon: "fas fa-comment", pathname: "/chat" },
  ];

  return (
    <>
      <div className="sidebar">
        <div id="sidebar-top">
          <Link to="/" className="logo">
            <img src={logo} alt="Cloudflare Logo" />
          </Link>
        </div>
        <ul className="menu-list">
          {menus.map((menu, index) => (
            <Link
              to={{ pathname: menu.pathname, state: menu.menu }}
              className={
                curSector === menu.menu ? "menu-item active" : "menu-item"
              }
              key={index}
            >
              <i className={menu.icon}></i>
              <div>{menu.menu}</div>
            </Link>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
