import React from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import user from "../../images/user.png";
import "./Header.scss";

const options = [
  { label: "LinkedIn: hueyk", year: 1994 },
  { label: "Github: hwuiwon", year: 1972 },
];

const Header = () => {
  return (
    <nav>
      <div id="header-list">
        <div id="search-bar">
          <Autocomplete
            options={options}
            sx={{ width: 250 }}
            renderInput={(params) => (
              <TextField {...params} label="Search post" size="small" />
            )}
          />
        </div>
        <img src={user} alt="Sample User" class="rounded" />
      </div>
    </nav>
  );
};

export default Header;
